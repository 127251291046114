import Vue from "vue";
import Router from "vue-router";
const je = require("json-encrypt");

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Components

const Forms = () => import("@/views/base/Forms");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

const userGuard = (to, from, next) => {
  let user = window.localStorage.getItem("user");
  let user_permissions = window.localStorage.getItem("user_permissions");

  if (user == null || user == undefined) {
    next("/");
  } else {
    user = JSON.parse(JSON.parse(je.decrypt(user)));
    if (user.api_token.length != 60) {
      next("/");
    } else {
      let isAuthenticated = false;
      if (user_permissions == null || user_permissions == undefined) {
        next({ name: "Page404" });
      } else {
        user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
        if (user_permissions.indexOf(to.name) > -1) isAuthenticated = true;
      }
      if (!isAuthenticated) next({ name: "Page404" });
      else next();
    }
  }
};

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Login",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          name: "LoginPage",
          component: Login,
        },
      ],
    },
    {
      path: "/inicio",
      redirect: "/inicio/inicio",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "inicio",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },


    {
      path: "/usuario",
      redirect: "/usuario/listar",
      name: "Usuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserList",
          component: () => import("@/views/user/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserAdd",
          component: () => import("@/views/user/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user",
          name: "UserEdit",
          component: () => import("@/views/user/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user",
          name: "UserView",
          component: () => import("@/views/user/View"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "profile",
          name: "UserProfile",
          component: () => import("@/views/pages/Profile"),
          props: true,
        },
      ],
    },

    {
      path: "/tipo-usuario",
      redirect: "/tipo-usuario/listar",
      name: "TipoUsuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserTypeList",
          component: () => import("@/views/usertype/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserTypeAdd",
          component: () => import("@/views/usertype/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user_type",
          name: "UserTypeEdit",
          component: () => import("@/views/usertype/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user_type",
          name: "UserTypeView",
          component: () => import("@/views/usertype/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/socio",
      redirect: "/socio/listar",
      name: "Partner",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "PartnerList",
          component: () => import("@/views/partner/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "PartnerAdd",
          component: () => import("@/views/partner/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_partner",
          name: "PartnerEdit",
          component: () => import("@/views/partner/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_partner",
          name: "PartnerView",
          component: () => import("@/views/partner/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/prestamo",
      redirect: "/prestamo/listar",
      name: "Loan",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "LoanList",
          component: () => import("@/views/loan/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "LoanAdd",
          component: () => import("@/views/loan/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_loan",
          name: "LoanEdit",
          component: () => import("@/views/loan/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_loan",
          name: "LoanView",
          component: () => import("@/views/loan/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/simulador-prestamo",
      redirect: "/simulador-prestamo/nuevo",
      name: "LoanSimulator",
      component: TheContainer,
      children: [
        {
          path: "nuevo",
          name: "LoanSimulatorAdd",
          component: () => import("@/views/loan-simulator/Add"),
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/pago-anticipado",
      redirect: "/pago-anticipado/listar",
      name: "Prepaid",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "PrepaidList",
          component: () => import("@/views/prepaid/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "PrepaidAdd",
          component: () => import("@/views/prepaid/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_prepaid",
          name: "PrepaidEdit",
          component: () => import("@/views/prepaid/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_prepaid",
          name: "PrepaidView",
          component: () => import("@/views/prepaid/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/pago-prestamo",
      redirect: "/pago-prestamo/listar",
      name: "LoanPayment",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "LoanPaymentList",
          component: () => import("@/views/loan-payment/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "LoanPaymentAdd",
          component: () => import("@/views/loan-payment/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_loan_payment",
          name: "LoanPaymentEdit",
          component: () => import("@/views/loan-payment/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_loan_payment",
          name: "LoanPaymentView",
          component: () => import("@/views/loan-payment/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/dato-financiero",
      redirect: "/dato-financiero/listar",
      name: "FinancialData",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "FinancialDataList",
          component: () => import("@/views/financial-data/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "FinancialDataAdd",
          component: () => import("@/views/financial-data/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_financial_data",
          name: "FinancialDataEdit",
          component: () => import("@/views/financial-data/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_financial_data",
          name: "FinancialDataView",
          component: () => import("@/views/financial-data/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/desembolso",
      redirect: "/desembolso/listar",
      name: "Check",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CheckList",
          component: () => import("@/views/check/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CheckAdd",
          component: () => import("@/views/check/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_check",
          name: "CheckEdit",
          component: () => import("@/views/check/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_check",
          name: "CheckView",
          component: () => import("@/views/check/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/caja",
      redirect: "/caja/listar",
      name: "Box",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BoxList",
          component: () => import("@/views/box/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BoxAdd",
          component: () => import("@/views/box/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_box",
          name: "BoxEdit",
          component: () => import("@/views/box/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_box",
          name: "BoxView",
          component: () => import("@/views/box/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/otros",
      redirect: "/otros/listar",
      name: "Other",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "OtherList",
          component: () => import("@/views/other/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "OtherAdd",
          component: () => import("@/views/other/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_other",
          name: "OtherEdit",
          component: () => import("@/views/other/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_other",
          name: "OtherView",
          component: () => import("@/views/other/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/egreso-extraordinario",
      redirect: "/egreso-extraordinario/listar",
      name: "ExtraordinaryExpense",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ExtraordinaryExpenseList",
          component: () => import("@/views/extraordinary-expense/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ExtraordinaryExpenseAdd",
          component: () => import("@/views/extraordinary-expense/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_extraordinary_expense",
          name: "ExtraordinaryExpenseEdit",
          component: () => import("@/views/extraordinary-expense/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_extraordinary_expense",
          name: "ExtraordinaryExpenseView",
          component: () => import("@/views/extraordinary-expense/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/empresa",
      redirect: "/empresa/editar",
      name: "Eempresa",
      component: TheContainer,
      children: [
        {
          path: "editar",
          name: "BusinessEdit",
          component: () => import("@/views/business/Edit"),
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/comprobante",
      redirect: "/comprobante/listar",
      name: "Voucher",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "VoucherList",
          component: () => import("@/views/voucher/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "VoucherAdd",
          component: () => import("@/views/voucher/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_voucher",
          name: "VoucherEdit",
          component: () => import("@/views/voucher/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_voucher",
          name: "VoucherView",
          component: () => import("@/views/voucher/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/aporte",
      redirect: "/aporte/listar",
      name: "Contribution",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ContributionList",
          component: () => import("@/views/contribution/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ContributionAdd",
          component: () => import("@/views/contribution/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_contribution",
          name: "ContributionEdit",
          component: () => import("@/views/contribution/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_contribution",
          name: "ContributionView",
          component: () => import("@/views/contribution/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/aporte-acumulado",
      redirect: "/aporte-acumulado/listar",
      name: "AccumulatedContribution",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "AccumulatedContributionList",
          component: () => import("@/views/accumulated-contribution/List"),
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_partner",
          name: "AccumulatedContributionView",
          component: () => import("@/views/accumulated-contribution/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/liquidacion-aporte",
      redirect: "/liquidacion-aporte/listar",
      name: "SettlementContribution",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "SettlementContributionList",
          component: () => import("@/views/settlement-contribution/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "SettlementContributionAdd",
          component: () => import("@/views/settlement-contribution/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_settlement_contribution",
          name: "SettlementContributionEdit",
          component: () => import("@/views/settlement-contribution/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_settlement_contribution",
          name: "SettlementContributionView",
          component: () => import("@/views/settlement-contribution/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/sepelio",
      redirect: "/sepelio/listar",
      name: "Burial",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BurialList",
          component: () => import("@/views/burial/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BurialAdd",
          component: () => import("@/views/burial/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_burial",
          name: "BurialEdit",
          component: () => import("@/views/burial/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_burial",
          name: "BurialView",
          component: () => import("@/views/burial/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/auditoria",
      redirect: "/auditoria/listar",
      name: "Audit",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "AuditList",
          component: () => import("@/views/audit/List"),
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/reporte",
      redirect: "/reporte/aportes",
      name: "Report",
      component: TheContainer,
      children: [
        {
          path: "Prestamos",
          name: "ReportLoanList",
          component: () => import("@/views/report/Loan"),
          beforeEnter: userGuard,
        },
        {
          path: "pago-prestamos",
          name: "ReportLoanPaymentList",
          component: () => import("@/views/report/LoanPayment"),
          beforeEnter: userGuard,
        },
        {
          path: "pago-prestamos-detallado",
          name: "ReportLoanPaymentDetailList",
          component: () => import("@/views/report/LoanPaymentDetail"),
          beforeEnter: userGuard,
        },
        {
          path: "aportes",
          name: "ReportContributionList",
          component: () => import("@/views/report/Contribution"),
          beforeEnter: userGuard,
        },
        {
          path: "aportes-detallado",
          name: "ReportContributionDetailList",
          component: () => import("@/views/report/ContributionDetail"),
          beforeEnter: userGuard,
        },
        {
          path: "movimiento",
          name: "ReportMovementList",
          component: () => import("@/views/report/Movement"),
          beforeEnter: userGuard,
        },
        {
          path: "estado-pago",
          name: "ReportPaymentStatusList",
          component: () => import("@/views/report/PaymentStatus"),
          beforeEnter: userGuard,
        },
      ],
    },

  


  ];
}
